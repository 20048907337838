import * as React from 'react';
import { Headline, Paragraph, Title } from '@bojagi/pablo/Typography';
import { Link } from 'gatsby';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { ClosingBox } from '../sections/ClosingBox';
import { Emoji } from '../components/Emoji';
import { SimpleText } from '../sections/SimpleText';

const IndexPage = () => (
  <Layout showLogo>
    <Seo
      title="Storybook"
      route="storybook"
      socialPreviewImage="bojagi-social-preview-storybook.jpg"
    />
    <SimpleText>
      <Headline>
        Bojagi <Emoji emoji="‍❤️" label="loves" /> Storybook
      </Headline>
      <Paragraph>
        At Bojagi, we bow to Storybook as a stellar tool for developing UI components{' '}
        <Emoji emoji="🙇" label="bow" />.<br />
        Bojagi supercharges your Storybook workflow.
      </Paragraph>
      <Title>Get feedback on live components</Title>
      <Paragraph>
        Storybook is a great local development tool and Bojagi makes it collaborative. Interact,
        comment and annotate on live <strong>React</strong> components and functionality. No
        screenshots – real implementation!
      </Paragraph>

      <Title>Share changed components within GitHub pull requests </Title>
      <Paragraph>
        Your newly changed components are available for UI and UX testing the moment your pull
        request is created.
      </Paragraph>

      <Title>What changes to make Bojagi work?</Title>
      <Paragraph>
        The good news is that your local Storybook workflow remains intact. Add the Bojagi CLI to
        preview the Storybook stories locally. Lastly, deploy the stories to the Bojagi server in
        your CI environment on each GitHub branch.
      </Paragraph>
      <Paragraph>
        The friendly Bojagi Bot will publish a list of changed components in the pull request and
        where to review them.
      </Paragraph>

      <Paragraph>
        See what we have to say to <Link to="/for-ceos">CEOs</Link>,{' '}
        <Link to="/for-designers">Designers</Link> and <Link to="/for-developers">Developers</Link>{' '}
        about Bojagi, too!
      </Paragraph>
    </SimpleText>
    <Container>
      <ClosingBox title="Convinced?">
        Try Bojagi today and see how it works in action. We are free during the open Beta!
      </ClosingBox>
    </Container>
  </Layout>
);

export default IndexPage;
